import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { GatsbyImage } from "gatsby-plugin-image";
import { MdAlternateEmail } from "react-icons/md";
import { RiLinkedinFill } from "react-icons/ri";

const TeamSection = () => {
	const data = useStaticQuery(graphql`
		query {
			robbieImg: wpMediaItem(title: { eq: "robbie-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			connorImg: wpMediaItem(title: { eq: "Connor" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			skyeImg: wpMediaItem(title: { eq: "Lauren-New" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	return (
		<Container fluid className="px-0 mx-0">
			<Row className="g-0 px-0 mx-0">
				<Col lg={4} className=" px-0 mx-0">
					<Row className="g-0 px-0 mx-0">
						<Col md={12}>
							<GatsbyImage
								image={data.robbieImg.gatsbyImage}
								alt={data.robbieImg.altText}
								className="w-100"
							/>
						</Col>
						<Col md={12}>
							<div className="w-100  position-relative bg-dark-grey text-center text-md-start d-md-flex align-items-center h-100">
								<div className="d-block p-5 pe-lg-3">
									<h2 className="text-white fs-3">ROBBIE MOULD</h2>
									<p className="text-white d-lg-none d-xl-block">Founder</p>
									<p
										style={{ fontSize: "100%" }}
										className="text-white d-none d-lg-block d-xl-none"
									>
										Founder
									</p>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.linkedin.com/in/robertmould/"
										className="primary-link me-3 d-inline-block"
									>
										<div
											style={{
												borderRadius: "100%",
												width: "25px",
												height: "25px",
											}}
											className="bg-white d-flex align-items-center justify-content-center"
										>
											<RiLinkedinFill
												style={{ width: "18px", height: "18px" }}
												className="primary-link"
											/>
										</div>
									</a>
									<a
										href="mailto:robbie@rjm.digital"
										className="primary-link  d-inline-block"
									>
										<div
											style={{
												borderRadius: "100%",
												width: "25px",
												height: "25px",
											}}
											className="bg-white d-flex align-items-center justify-content-center"
										>
											<MdAlternateEmail
												style={{ width: "18px", height: "18px" }}
												className="primary-link"
											/>
										</div>
									</a>
								</div>
							</div>
						</Col>
					</Row>
				</Col>
				<Col lg={4} className=" px-0 mx-0">
					<Row className="g-0 px-0 mx-0">
						<Col md={12}>
							<GatsbyImage
								image={data.skyeImg.gatsbyImage}
								alt={data.skyeImg.altText}
								className="w-100"
							/>
						</Col>
						<Col md={12}>
							<div className="w-100  position-relative bg-med-grey text-center text-md-start d-md-flex align-items-center  h-100">
								<div className="d-block pe-lg-3 p-5">
									<h2 className="text-primary text-uppercase fs-3">
										Lauren Williams
									</h2>
									<p className="text-primary d-lg-none d-xl-block">
										Digital Marketing
									</p>
									<p
										style={{ fontSize: "100%" }}
										className="text-primary d-none d-lg-block d-xl-none"
									>
										Digital Marketing
									</p>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.linkedin.com/in/llwilliams/"
										className="white-link-yellow me-3 d-inline-block"
									>
										<div
											style={{
												borderRadius: "100%",
												width: "25px",
												height: "25px",
											}}
											className="bg-primary d-flex align-items-center justify-content-center"
										>
											<RiLinkedinFill
												style={{ width: "18px", height: "18px" }}
												className="white-link-yellow"
											/>
										</div>
									</a>
									<a
										href="mailto:lauren@rjm.digital"
										className="white-link-yellow  d-inline-block"
									>
										<div
											style={{
												borderRadius: "100%",
												width: "25px",
												height: "25px",
											}}
											className="bg-primary d-flex align-items-center justify-content-center"
										>
											<MdAlternateEmail
												style={{ width: "18px", height: "18px" }}
												className="white-link-yellow"
											/>
										</div>
									</a>
								</div>
							</div>
						</Col>
					</Row>
				</Col>
				<Col lg={4} className=" px-0 mx-0">
					<Row className="g-0 px-0 mx-0">
						<Col md={12}>
							<GatsbyImage
								image={data.connorImg.gatsbyImage}
								alt={data.connorImg.altText}
								className="w-100"
							/>
						</Col>
						<Col md={12}>
							<div className="w-100  position-relative bg-dark-grey text-center text-md-start d-md-flex align-items-center h-100">
								<div className="d-block p-5 pe-lg-3">
									<h2 className="text-white fs-3">CONNOR ARCHIBALD</h2>
									<p className="text-white d-lg-none d-xl-block">
										Web Developer
									</p>
									<p
										style={{ fontSize: "100%" }}
										className="text-white d-none d-lg-block d-xl-none"
									>
										Web Developer
									</p>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.linkedin.com/in/connor-archibald-a790b2159/"
										className="primary-link me-3 d-inline-block"
									>
										<div
											style={{
												borderRadius: "100%",
												width: "25px",
												height: "25px",
											}}
											className="bg-white d-flex align-items-center justify-content-center"
										>
											<RiLinkedinFill
												style={{ width: "18px", height: "18px" }}
												className="primary-link"
											/>
										</div>
									</a>
									<a
										href="mailto:devs@rjm.digital"
										className="primary-link  d-inline-block"
									>
										<div
											style={{
												borderRadius: "100%",
												width: "25px",
												height: "25px",
											}}
											className="bg-white d-flex align-items-center justify-content-center"
										>
											<MdAlternateEmail
												style={{ width: "18px", height: "18px" }}
												className="primary-link"
											/>
										</div>
									</a>
								</div>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default TeamSection;
